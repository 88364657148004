import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton } from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { platformList } from '../../constants/platformList';
import { GRAINID_GRAIN_MAP, getGrainHeader } from '../../helpers/pricing';
import { filterNameOrAlias } from '../../helpers/utils';
import { PlatformLogo } from '../atoms/PlatformLogo';
import StyledChip from '../atoms/StyledChip';
import StyledDataGrid from '../atoms/StyledDataGrid';
import DataGridToolbar from '../molecules/DataGridToolbar';

export const PricingTable = ({
    rows,
    config,
    setSelectedRow,
    setGrain,
    setOpen,
    setPricingRules,
}) => {
    const [selectedPage, setSelectedPage] = useState(0);
    const [selectedPageSize, setSelectedPageSize] = useState(10);

    const handleEdit = rowToEdit => {
        const grain = get(GRAINID_GRAIN_MAP, rowToEdit.grain, null);
        const selectedRow = rows?.find(row => row.id === rowToEdit.id);
        setSelectedRow(selectedRow);
        setGrain(grain);
        setOpen(true);
    };

    const handleDelete = rowToDelete => {
        const grain = get(GRAINID_GRAIN_MAP, rowToDelete.grain, null);
        setPricingRules(oldPricingRules => {
            const newPricingRules = { ...oldPricingRules };
            newPricingRules[grain] = newPricingRules[grain].filter(
                rule => rule.id !== rowToDelete.id,
            );

            return newPricingRules;
        });
    };

    const generateColumns = grain => {
        const columns = [
            {
                field: 'calculation_type',
                headerName: 'Adjustment Type',
                width: 150,
                renderCell: ({ row }) => (
                    <StyledChip
                        label={row.calculation_type.name}
                        key={row.id}
                        sx={{ textTransform: 'none' }}
                    />
                ),
            },
            {
                field: 'names',
                headerName: getGrainHeader(grain),
                minWidth: 250,
                width: 500,
                getApplyQuickFilterFn:
                    searchTerm =>
                    ({ row }) =>
                        row.grains
                            .map(({ name }) => filterNameOrAlias(name, name, searchTerm))
                            .some(val => !!val),
                renderCell: ({ row }) => {
                    return (
                        <Grid container>
                            {row.grains?.map(({ name, alias, platform }) => {
                                return (
                                    <StyledChip
                                        key={`${name}-${platform}}`}
                                        label={alias?.config?.alias || name}
                                        sx={{
                                            mr: '5px',
                                            mb: '5px',
                                            mt: '5px',
                                            textTransform: 'none',
                                            '&&:hover': { backgroundColor: 'white' },
                                        }}
                                        icon={
                                            <Box>
                                                <PlatformLogo
                                                    platform={platformList[platform]}
                                                    placement={'top'}
                                                />
                                            </Box>
                                        }
                                    />
                                );
                            })}
                        </Grid>
                    );
                },
            },
            {
                field: 'value',
                headerName: 'Value',
                flex: 1,
                valueGetter: ({ row }) => {
                    if (!row.value) {
                        return;
                    }

                    return row.calculation_type.symbol === '$' ? `$${row.value}` : `${row.value}%`;
                },
            },
            { field: 'start_date', headerName: 'Effective Date', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                headerAlign: 'center',
                renderCell: params => (
                    <Grid container alignItems='center'>
                        <IconButton aria-label='edit' onClick={() => handleEdit(params.row)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton aria-label='delete' onClick={() => handleDelete(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                ),
            },
        ];
        return columns;
    };

    return (
        <StyledDataGrid
            rows={rows}
            getRowHeight={() => 'auto'}
            columns={generateColumns(config.grain)}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={selectedPageSize}
            page={selectedPage}
            onPageChange={page => {
                setSelectedPage(page);
            }}
            onPageSizeChange={pageSize => setSelectedPageSize(pageSize)}
            components={{
                Toolbar: () => DataGridToolbar({ columnHide: true }),
            }}
            componentsProps={{
                toolbar: {
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            localeText={{
                toolbarFilters: '',
                toolbarColumns: 'Add Fields',
            }}
            hideFooterSelectedRowCount
            disableDensitySelector
            disableRowSelectionOnClick
            autoHeight={true}
            sx={{
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
            }}
        />
    );
};
